import React from "react"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form-c"


const ContactUsPage = () => (
  <>
    <SEO title="Contact Us" />
    <ContactForm id="contact-form" />
  </>
);

export default ContactUsPage;


