import React, { Component } from 'react'

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Email: "",
          MultiLine: "",
          PhoneNumber_countrycode: "",
          SingleLine: "",
          SingleLine1: "",
          SingleLine2: "",
          SelectedSizes: []
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCalculatorInputChange = this.handleCalculatorInputChange.bind(this);
        this.handleCustomSelectChange = this.handleCustomSelectChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: target.value
        });
    }

    handleCalculatorInputChange(event) {
        const target = event.target;
        const name1 = target.name;
        const name2 = (name1 === "SingleLine") ? this.state.SingleLine1 : this.state.SingleLine;

        if (name2 === "" || target.value === "") {
            this.setState({
                [name1]: target.value,
                SingleLine2: ""
            }); 
        } else {
            this.setState({
                [name1]: target.value,
                SingleLine2: "$ " + parseInt(target.value) * parseInt(name2) + ".00"
            });
        }
    }

    handleCustomSelectChange(event) {
        console.log(event.target);
        console.log(event.target.value);
        const selectedSize = event.target.value;
        const indexOfSelected = this.state.SelectedSizes.indexOf(selectedSize);
        const numPicked = this.state.SelectedSizes.length;
        if (indexOfSelected == -1) {
            this.setState(prevState => ({
                SelectedSizes: prevState.SelectedSizes.concat(selectedSize)
            }));
        } else if (numPicked == 1) {
                this.setState(() => ({
                    SelectedSizes: []
                }));
        } else {
            this.setState((prevState) => ({
                SelectedSizes: prevState.SelectedSizes.filter(size => size !== selectedSize)
            }));
        }
        console.log(this.state.SelectedSizes);
    }
    
    render() {
        return (
            <form  className= "cf" action='https://forms.zohopublic.com/canvascraze1/form/contactus/formperma/IZpwZBnC9W8ICYwrCN6R692aUxzPSxBCatYL5pUF-bE/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
                <div className="half left cf">
                    <input type="text" id="input-name" maxlength="255" name="Name_First" fieldType="7" placeHolder="First name" />
                    <input type="text" maxlength="255" name="Email" value={this.state.Email} onChange={this.handleInputChange} fieldType="9" id="email-input" placeHolder="Email address" />
                </div>
                <div className="half right cf">
                    <input type="text" id="input-name" maxlength="255" name="Name_Last" fieldType="7" placeHolder="Last name" />
                    <input type="text" compname="PhoneNumber" name="PhoneNumber_countrycode" 
                            phoneFormat="1" isCountryCodeEnabled="false" maxlength="20" value={this.state.PhoneNumber_countrycode} 
                            onChange={this.handleInputChange} fieldType="11" id="international_PhoneNumber_countrycode" placeHolder="Phone number (optional)" />
                </div>
                <div className="bottom">
                    <textarea type="text" name="MultiLine" maxlength="65535" id="input-message" placeHolder="Message *Image uploads are limited to five persubmission. If multiple images and sizes are selected, please detail which size goes with which image in your message. Thank you!"></textarea>
                    <div id="image-upload">
                        <div id="select-size-container">
                            <p>Select Size</p>
                            <input style={{display: 'none'}}  type="text" name="SingleLine2" value="" fieldType="1" maxlength="255" />
                            <div id ="multiple-choice-container">
                                <select name="MultipleChoice" multiple="multiple" value={this.state.SelectedSizes} onClick={this.handleCustomSelectChange} >
                                    <option value="Custom&#x20;Size">Custom&#x20;Size</option>
                                    <option value="8&#x20;x&#x20;8&#x20;&#x3d;&#x20;&#x24;23">8&#x20;x&#x20;8&#x20;&#x3d;&#x20;&#x24;23</option>
                                    <option value="8&#x20;x&#x20;10&#x20;&#x3d;&#x20;&#x24;25">8&#x20;x&#x20;10&#x20;&#x3d;&#x20;&#x24;25</option>
                                    <option value="40&#x20;x&#x20;60&#x20;&#x3d;&#x20;&#x24;175">40&#x20;x&#x20;60&#x20;&#x3d;&#x20;&#x24;175</option>
                                    <option value="30&#x20;x&#x20;40&#x20;&#x3d;&#x20;&#x24;120">30&#x20;x&#x20;40&#x20;&#x3d;&#x20;&#x24;120</option>
                                    <option value="30&#x20;x&#x20;36&#x20;&#x3d;&#x20;&#x24;110">30&#x20;x&#x20;36&#x20;&#x3d;&#x20;&#x24;110</option>
                                    <option value="25&#x20;x&#x20;25&#x20;&#x3d;&#x20;&#x24;73">25&#x20;x&#x20;25&#x20;&#x3d;&#x20;&#x24;73</option>
                                    <option value="24&#x20;x&#x20;48&#x20;&#x3d;&#x20;&#x24;135">24&#x20;x&#x20;48&#x20;&#x3d;&#x20;&#x24;135</option>
                                    <option value="24&#x20;x&#x20;36&#x20;&#x3d;&#x20;&#x24;90">24&#x20;x&#x20;36&#x20;&#x3d;&#x20;&#x24;90</option>
                                    <option value="24&#x20;x&#x20;34&#x20;&#x3d;&#x20;&#x24;85">24&#x20;x&#x20;34&#x20;&#x3d;&#x20;&#x24;85</option>
                                    <option value="20&#x20;x&#x20;30&#x20;&#x3d;&#x20;&#x24;84">20&#x20;x&#x20;30&#x20;&#x3d;&#x20;&#x24;84</option>
                                    <option value="20&#x20;x&#x20;20&#x20;&#x3d;&#x20;&#x24;63">20&#x20;x&#x20;20&#x20;&#x3d;&#x20;&#x24;63</option>
                                    <option value="18&#x20;x&#x20;18&#x20;&#x3d;&#x20;&#x24;47">18&#x20;x&#x20;18&#x20;&#x3d;&#x20;&#x24;47</option>
                                    <option value="16&#x20;x&#x20;24&#x20;&#x3d;&#x20;&#x24;55">16&#x20;x&#x20;24&#x20;&#x3d;&#x20;&#x24;55</option>
                                    <option value="16&#x20;x&#x20;20&#x20;&#x3d;&#x20;&#x24;45">16&#x20;x&#x20;20&#x20;&#x3d;&#x20;&#x24;45</option>
                                    <option value="16&#x20;x&#x20;16&#x20;&#x3d;&#x20;&#x24;41">16&#x20;x&#x20;16&#x20;&#x3d;&#x20;&#x24;41</option>
                                    <option value="14&#x20;x&#x20;14&#x20;&#x3d;&#x20;&#x24;36">14&#x20;x&#x20;14&#x20;&#x3d;&#x20;&#x24;36</option>
                                    <option value="12&#x20;x&#x20;18&#x20;&#x3d;&#x20;&#x24;40">12&#x20;x&#x20;18&#x20;&#x3d;&#x20;&#x24;40</option>
                                    <option value="12&#x20;x&#x20;12&#x20;&#x3d;&#x20;&#x24;30">12&#x20;x&#x20;12&#x20;&#x3d;&#x20;&#x24;30</option>
                                    <option value="11&#x20;x&#x20;14&#x20;&#x3d;&#x20;&#x24;34">11&#x20;x&#x20;14&#x20;&#x3d;&#x20;&#x24;34</option>
                                </select>
                            </div>
                        </div>
                        <div id="upload-image-container">
                            <label>Upload Image</label>
                            <input type="file" accept="image/*" name="ImageUpload" checktype="c1"  multiple="multiple"/>
                        </div>
                    </div>
                    <input type="submit" value="Submit" id="input-submit" />
                </div>
            </form>
        );
    }
}

export default ContactForm;